














































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { DutyManagementEntityModel, DutyManagementQueryModel } from '@/entity-model/duty-management-entity';
import DutyManagementService from '@/service/duty-management';

@Component
export default class DutyManagementListComponent extends TableDialogFormComponent<DutyManagementEntityModel, DutyManagementQueryModel> {
    DutyManagementEntityModel = DutyManagementEntityModel;
    created() {
        this.initTable({
            service: DutyManagementService,
            queryModel: new DutyManagementQueryModel(),
            tableColumns: DutyManagementEntityModel.getTableColumns()
        });
        this.getList();
    }

    detailClick(record) {
        // 排版设计
        this.$router.push(`/dashboard/duty-management/${record.id}`);
    }
}

